import React, { useState } from 'react';
import BigNumber from 'bignumber.js';
import { faAngleDown, faAngleUp, faCalculator } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import FarmHarvestSection from '../FarmHarvestSection';
import FarmStakeSection from '../FarmStakeSection';
import FarmRowDetailsSection from './components/FarmRowDetailsSection';

import './FarmRow.css';

const FarmRow = ({ farmData, handleRoiCalculatorModalOpen, handleWithdrawModalOpen, handleDepositModalOpen, nativeTokenPriceDefault, lastItem = false }) => {
  const [showForms, setShowForms] = useState(false)
  const farmImageSrc = () => `/images/farms/${farmData.name}.png`.replace(' LP', '').toLowerCase();

  const farmMultiplier = () => `${new BigNumber(farmData.allocPoint).div(100).toString()}X`;

  const earned = () => {
    const decimals = process.env.REACT_APP_DECIMALS;

    return new BigNumber(farmData.pendingToken)
      .div(new BigNumber(10).pow(decimals))
      .toFormat(6);
  }

  const toggleShowForms = () => setShowForms(prevState => !prevState)

  return (
    <div className={ lastItem ? '' : 'has-border-bottom' }>
      <div className="p-3">
        <div className="columns is-multiline is-mobile is-align-items-center">
          <div className="column is-one-thirds-mobile is-narrow">
            <figure className="image is-48x48">
              <img src={ farmImageSrc() } alt={ farmData.name } />
            </figure>
          </div>
          <div className="column is-two-thirds-mobile">
            <h5 className="title is-5 mb-0">{ farmData.name }</h5>
            <span className="tag is-rounded is-primary">{ farmData.swap }</span>
          </div>
          <div className="column is-half-mobile is-2-desktop">
            <label>Earned</label>
            <h6 className="has-text-weight-semibold">{ earned() }</h6>
          </div>
          <div className="column is-half-mobile is-2-desktop is-clickable" onClick={ () => handleRoiCalculatorModalOpen(farmData) }>
            <label>
              <span className="icon-text">
                <span className="icon has-text-success">
                  <FontAwesomeIcon icon={ faCalculator } />
                </span>
                APR
              </span>
            </label>
            <h6 className="has-text-weight-semibold">
              { new BigNumber(farmData.apr).times(100).toFormat(2) }%
            </h6>
          </div>
          <div className="column is-half-mobile is-2-desktop">
            <label>Liquidity</label>
            <h6 className="has-text-weight-semibold">${ new BigNumber(farmData.totalValue).toFormat(2) }</h6>
          </div>
          <div className="column is-half-mobile is-2-desktop">
            <div className="is-flex is-align-items-center">
              <div>
                <label>Multiplier</label>
                <h6 className="has-text-weight-semibold">{ farmMultiplier() }</h6>
              </div>
              <button className="button is-ghost ml-auto" onClick={ toggleShowForms }>
                <FontAwesomeIcon icon={ showForms ? faAngleUp : faAngleDown } />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={ `p-3 ${showForms ? null : 'is-hidden'}` }>
        <div className="columns has-background-light">
          <div className="column is-order-2-desktop">
            <div className="box">
              <FarmHarvestSection farmData={ farmData } />
            </div>
          </div>
          <div className="column is-flex is-order-3-desktop">
            <div className="box is-flex-grow-1">
              <FarmStakeSection farmData={ farmData } handleWithdrawModalOpen={ handleWithdrawModalOpen } handleDepositModalOpen={ handleDepositModalOpen } nativeTokenPriceDefault={ nativeTokenPriceDefault } />
            </div>
          </div>
          <div className="column is-order-1-desktop">
            <FarmRowDetailsSection farmData={ farmData } nativeTokenPriceDefault={ nativeTokenPriceDefault } />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FarmRow;
