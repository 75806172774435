const farmInitialState = {
  loading: false,
  firstLoad: true,
  nativeTokenPrice: 0,
  nativeTokenPriceDefault: false,
  tvl: 0,
  tnl: 0,
  maxFarmApr: 0,
  tokenTotalSupply: 0,
  tokenTotalBurned: 0,
  tokenPerSecond: 0,
  tokenMaximumSupply: 0,
  farms: [
    // FARMS
    {
      pid: 1,
      token: 'banksy',
      quoteToken: 'ftm',
      isTokenOnly: false,
      name: 'BANKSY-WFTM LP',
      swap: 'SpookySwap',
    },
    {
      pid: 0,
      token: 'banksy',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'BANKSY-USDC LP',
      swap: 'SpookySwap',
    },
    {
      pid: 3,
      token: 'ftm',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'WFTM-USDC LP',
      swap: 'SpookySwap',
    },
    {
      pid: 4,
      token: 'dai',
      quoteToken: 'ftm',
      isTokenOnly: false,
      name: 'DAI-WFTM LP',
      swap: 'SpookySwap',
    },
    {
      pid: 5,
      token: 'eth',
      quoteToken: 'ftm',
      isTokenOnly: false,
      name: 'ETH-WFTM LP',
      swap: 'SpookySwap',
    },
    {
      pid: 6,
      token: 'dai',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'DAI-USDC LP',
      swap: 'SpookySwap',
    },
    // POOLS
    {
      pid: 2,
      token: 'banksy',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'BANKSY',
      swap: 'SpookySwap',
    },
    {
      pid: 7,
      token: 'ftm',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'WFTM',
      swap: 'SpookySwap',
    },
    {
      pid: 11,
      token: 'usdc',
      quoteToken: 'dai',
      isTokenOnly: true,
      name: 'USDC',
      swap: 'SpookySwap',
    },
    {
      pid: 10,
      token: 'dai',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'DAI',
      swap: 'SpookySwap',
    },
    {
      pid: 8,
      token: 'eth',
      quoteToken: 'ftm',
      isTokenOnly: true,
      name: 'ETH',
      swap: 'SpookySwap',
    },
    {
      pid: 9,
      token: 'btc',
      quoteToken: 'ftm',
      isTokenOnly: true,
      name: 'BTC',
      swap: 'SpookySwap',
    },
    {
      pid: 12,
      token: 'link',
      quoteToken: 'ftm',
      isTokenOnly: true,
      name: 'LINK',
      swap: 'SpookySwap',
    },
  ],
}

export default farmInitialState;
