const contractsMainNet = new Map();
const contractsTestNet = new Map();

// internal
contractsMainNet.set('masterChef', '0x6daa10F9D8F3EBAc21BEcA9edC8b86EE32E33cD0');
contractsMainNet.set('multiCall', '0xa637f776832a01d1b99DEb4B2Ee34eB5662A3FF5');

contractsMainNet.set('vaultChef', '0x293520e3127A824dfc378B94c3E55538A761C53C');

contractsMainNet.set('nftChef', '0x9C26e24ac6f0EA783fF9CA2bf81543c67cf446d2');
// contractsMainNet.set('nftChef', '0x82D86C9e898033D6c0a4d729Bfcd2b5C5c91f0E0'); // TEST
contractsMainNet.set('nftSale0', '0xCB88E4dafAa9d3eBFd7b182E510966f073812919');
contractsMainNet.set('nftSale1', '0x466974aF1DD88374E48bac36cA477b8b177bb7b3');
contractsMainNet.set('nftSale2', '0x2058844B27f8A36bf3f4d9FAcB02A1a36af7Fa30');
contractsMainNet.set('nftSale3', '0xb4827169A802B05bc6605a20C3e246d35955Adce');
contractsMainNet.set('nftAirdrop', '0x65D98D4A4C7cE9d2FE27f79d77Cb91E66e936fed');
contractsMainNet.set('nftMerge', '0x146dFbAa92d995e5dFb64Ab88cCC343A7F07d8A7');

contractsMainNet.set('preBanksy', '0x2309D82E144d3Dda5c976a8867B3EBb7b0E78354');
contractsMainNet.set('preBanksySwap', '0x7e32423C1467bD2240390979f8C190836F1f677B');
// single tokens
contractsMainNet.set('banksy', '0x17230A02f23722f5e2afb0fB1F359d6905c7a678');
contractsMainNet.set('ftm', '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83');
contractsMainNet.set('eth', '0x74b23882a30290451A17c44f4F05243b6b58C76d');
contractsMainNet.set('btc', '0x321162Cd933E2Be498Cd2267a90534A804051b11');
contractsMainNet.set('dai', '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E');
contractsMainNet.set('usdc', '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75');
contractsMainNet.set('link', '0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8');
// LPs
contractsMainNet.set('banksy-ftm', '0xD1Ba3400B5ee6F4fEA50Cd9eC68B39DA6F118910');
contractsMainNet.set('banksy-usdc', '0xe7bb84cFadb535969D692E237916684804446b66');
contractsMainNet.set('ftm-usdc', '0x2b4C76d0dc16BE1C31D4C1DC53bF9B45987Fc75c');
contractsMainNet.set('ftm-dai', '0xe120ffBDA0d14f3Bb6d6053E90E63c572A66a428');
contractsMainNet.set('eth-ftm', '0xf0702249F4D3A25cD3DED7859a165693685Ab577');
contractsMainNet.set('dai-usdc', '0x484237bc35cA671302d19694c66d617142FBC235');
// LPs utilities
contractsMainNet.set('link-ftm', '0x89d9bC2F2d091CfBFc31e333D6Dc555dDBc2fd29');
contractsMainNet.set('btc-ftm', '0xFdb9Ab8B9513Ad9E419Cf19530feE49d412C3Ee3');
contractsMainNet.set('dai-ftm', '0xe120ffbda0d14f3bb6d6053e90e63c572a66a428');
// Fake
contractsMainNet.set('preBanksy-ftm', '0xD1Ba3400B5ee6F4fEA50Cd9eC68B39DA6F118910'); // for pool#1
// contractsMainNet.set('fdai-ftm', '0xe120ffbda0d14f3bb6d6053e90e63c572a66a428');
// contractsMainNet.set('fdai', '0x3573E1C88D66073F1d5d497a12EB7eb796a244CE');
// contractsMainNet.set('fusdc', '0x3064EDEE4ADA222806db307b90A0b7A36B4cbd18');
// contractsMainNet.set('fbanksy', '0xdB1d67c070D4D4aE74E70A4EB790E3b643E9a30c');
// contractsMainNet.set('fbanksy-ftm', '0xa9fd3FBb9af4736ea62994f3618a23b1a9e3dbE3');
// contractsMainNet.set('fbanksy-usdt', '0x990F27a2944aB70Dca1bCF0334Cb7ED28e91081a');

export { contractsMainNet, contractsTestNet };
