import BigNumber from 'bignumber.js';
import React, { useContext } from 'react';

import { NftContext } from '../../../../../context/nft';

const PoolCardNftSection = ({ poolData }) => {
  const { nftState } = useContext(NftContext);

  const nftData = (nftState.myNfts || []).find(data => data.pid === poolData.userNftID);

  const nftName = (nftData) => {
    return `Morons #${nftData?.pid}`;
  }

  return (
    <>
      {nftData ? (
        <div className="mb-5">
          <figure className="image is-16by9 mb-3">
            <img src={ `/images/nfts/generation-${nftData.generation}.png` } alt={ nftName(nftData) } />
          </figure>
          <div className="field is-grouped is-justify-content-center">
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Exp.</span>
                <span className="tag mb-0 is-success">{ new BigNumber(nftData.experience).toFormat(0) }</span>
              </div>
            </div>

            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Power</span>
                <span className="tag mb-0 is-primary">{ nftData.power }</span>
              </div>
            </div>

            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Boost Stake</span>
                <span className="tag mb-0 is-info">{ nftData ? new BigNumber(nftData.boostStake).toFormat(2) : 0 }%</span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default PoolCardNftSection;
