import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faDollarSign, faHandHoldingUsd, faTractor } from '@fortawesome/free-solid-svg-icons';

import { swapAddress } from '../../../utils/commons';

const MoneyRecipeSection = () => {
  const exchangeUrl = swapAddress({
    isTokenOnly: true,
    swap: 'SpookySwap',
    token: process.env.REACT_APP_NATIVE_TOKEN,
    quoteToken: process.env.REACT_APP_NETWORK_TOKEN,
  });

  return (
    <>
      <h4 className="has-text-centered is-size-4">Money Recipe</h4>
      <ul className="steps is-narrow is-medium is-centered has-content-centered">
        <li className="steps-segment has-gaps">
          <a href={ exchangeUrl } target="_blank" rel="noreferrer" className="has-text-dark">
            <span className="steps-marker">
              <span className="icon">
                <FontAwesomeIcon icon={ faCoins } />
              </span>
            </span>
            <div className="steps-content">
              <p className="heading">Buy Banksy</p>
            </div>
          </a>
        </li>
        <li className="steps-segment has-gaps">
          <Link to="/farms" className="has-text-dark">
            <span className="steps-marker">
              <span className="icon">
                <FontAwesomeIcon icon={ faTractor } />
              </span>
            </span>
            <div className="steps-content">
              <p className="heading">Farm</p>
            </div>
          </Link>
        </li>
        <li className="steps-segment has-gaps">
          <Link to="/farms" className="has-text-dark">
            <span className="steps-marker">
              <span className="icon">
                <FontAwesomeIcon icon={ faHandHoldingUsd } />
              </span>
            </span>
            <div className="steps-content">
              <p className="heading">Harvest</p>
            </div>
          </Link>
        </li>
        <li className="steps-segment is-active">
          <Link to="/walls" className="has-text-dark">
            <span className="steps-marker">
              <span className="icon">
                <FontAwesomeIcon icon={ faDollarSign } />
              </span>
            </span>
            <div className="steps-content">
              <p className="heading">Wall-it</p>
            </div>
          </Link>
        </li>
      </ul>
    </>
  );
}

export default MoneyRecipeSection;
