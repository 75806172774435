import { useState } from 'react';
import BigNumber from 'bignumber.js';

import { nativeTokenName } from '../../../utils/commons';

import { calcApy } from '../../../utils/farms';

const FarmRoiCalculatorModal = ({ farmData, isModalActive, onModalClose, nativeTokenPrice }) => {
  const [val, setVal] = useState('1000');
  const [numberOfDays, setNumberOfDays] = useState(1);

  const setValue = (newValue) => {
    setVal(newValue);
  }

  const onValChange = (evt) => {
    const { target } = evt;
    setVal(target.value);
  }

  const calculateTokenEarned = () => {
    let amount = Number(val);
    if (isNaN(amount)) {
      amount = 0;
    }
    const principal = amount / nativeTokenPrice;
    const apr = new BigNumber(farmData.apr).times(new BigNumber(100)).toNumber();

    const finalAmount = principal * (1 + calcApy(apr, 1, numberOfDays, 0));
    const interestEarned = finalAmount - principal;

    return new BigNumber(interestEarned);
  }

  const apyModalRoi = () => {
    let amount = Number(val);
    if (isNaN(amount)) {
      amount = 0;
    }
    const amountInvested = amount / nativeTokenPrice.toNumber();
    const percentage = calculateTokenEarned().div(amountInvested).times(100).toNumber();

    return new BigNumber(percentage);
  }

  const sanitizeValue = (value) => {
    return value.gt(new BigNumber(10).pow(10)) ? 'INSANE' : value.toFormat(2);
  }

  return (
    <div className={`modal ${isModalActive ? 'is-active' : null}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">ROI Calculator</p>
          <button className="delete" aria-label="close" onClick={ onModalClose }></button>
        </header>
        <section className="modal-card-body">
          <div className="buttons is-justify-content-flex-end mb-0">
            <button className={ `button is-outlined is-small ${val === '100' ? 'is-active' : null}`} onClick={ () => setValue('100') }>
              $100
            </button>
            <button className={ `button is-outlined is-small ${val === '1000' ? 'is-active' : null}`} onClick={ () => setValue('1000') }>
              $1000
            </button>
            <button className={ `button is-outlined is-small ${val === '10000' ? 'is-active' : null}`} onClick={ () => setValue('10000') }>
              $10000
            </button>
          </div>
          <div className="field has-addons">
            <div className="control is-expanded">
              <input className="input has-text-right" type="text" placeholder="0" value={ val } onChange={ onValChange } />
            </div>
            <div className="control">
              <button type="button" className="button is-static">$</button>
            </div>
          </div>
          <div className="buttons has-addons is-centered">
            <button className={ `button is-rounded ${numberOfDays === 1 ? 'is-success is-selected' : null}` } onClick={ () => setNumberOfDays(1) }>1D</button>
            <button className={ `button is-rounded ${numberOfDays === 7 ? 'is-success is-selected' : null}` } onClick={ () => setNumberOfDays(7) }>7D</button>
            <button className={ `button is-rounded ${numberOfDays === 30 ? 'is-success is-selected' : null}` } onClick={ () => setNumberOfDays(30) }>30D</button>
            <button className={ `button is-rounded ${numberOfDays === 365 ? 'is-success is-selected' : null}` } onClick={ () => setNumberOfDays(365) }>365D</button>
          </div>
          <article className="message is-link">
            <div className="message-body">
              <p>ROI at current rates</p>
              <p className="title">
                { sanitizeValue(calculateTokenEarned()) } <small>{ nativeTokenName }</small>
              </p>
              <p className="subtitle ml-2 mb-0">
                { sanitizeValue(apyModalRoi()) }%
              </p>
            </div>
          </article>
        </section>
        <footer className="modal-card-foot is-justify-content-flex-end">
          <div className="content mr-3 is-size-7">
            <ul>
              <li>Calculated based on current rates.</li>
              <li>Compounding once daily.</li>
              <li>Rates are estimates provided for your convenience only, and by no means represent guaranteed returns.</li>
            </ul>
          </div>
          <button className="button" onClick={ onModalClose }>Close</button>
        </footer>
      </div>
    </div>
  );
}

export default FarmRoiCalculatorModal;
