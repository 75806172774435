import React, { useContext, useState } from "react";
import BigNumber from 'bignumber.js';
import  { withdrawPoolV1 } from '../../../../../blockchain/pools';

import { ToastContext } from '../../../../../context/toast';

const PoolCardWithdrawModal = ({ poolData, isModalActive, onModalClose, stakedBalance }) => {
  const { addToast } = useContext(ToastContext);

  const [pendingTx, setPendingTx] = useState(false);

  const handleWithdraw = async () => {
    let tx;
    try {
      setPendingTx(true);
      tx = await withdrawPoolV1(poolData.address);
      await tx.wait();
      setPendingTx(false);
      addToast('Wall Withdraw all succeeded', 'is-success');
      onModalClose();
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if(tx.error !== undefined) {
      console.log('error', tx.error);
      setPendingTx(false);
      addToast('Wall Withdraw all failed', 'is-danger');
    }
  }

  return (
    <div className={`modal ${isModalActive ? 'is-active' : null}`}>
      <div className="modal-background" />
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Withdraw ALL</p>
          <button className="delete" aria-label="close" onClick={ onModalClose }></button>
        </header>
        <section className="modal-card-body">
          <div className="field">
            <label className="label">Rewards to return</label>
            <div className="field has-addons">
              <div className="control is-expanded">
                <input className="input" readOnly type="text" value={ new BigNumber(poolData.userPendingReward).div(new BigNumber(10).pow(poolData.rewardTokenDecimals)).toFormat(Number(process.env.REACT_APP_DECIMALS)) } placeholder="Reward" />
              </div>
              <p className="control">
                <button type="button" className="button is-static">{ poolData.rewardToken.toUpperCase() }</button>
              </p>
            </div>
          </div>
          <div className="field">
            <label className="label">Deposited to return</label>
            <div className="field has-addons">
              <div className="control is-expanded">
                <input className="input" readOnly type="text" value={ stakedBalance } placeholder="Reward" />
              </div>
              <p className="control">
                <button type="button" className="button is-static">{ poolData.stakedToken.toUpperCase() }</button>
              </p>
            </div>
          </div>
          { poolData.userHasNFT ? (
            <div className="field">
              <label className="label">Earned Experience on your NFT ArtWork:</label>
              <div className="field has-addons">
                <div className="control is-expanded">
                  <input className="input" readOnly type="text" value={ new BigNumber(poolData.userPendingExperience || 0).plus(poolData.userExperience).div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS)).toFormat(Number(process.env.REACT_APP_DECIMALS)) } placeholder="Reward" />
                </div>
                <p className="control">
                  <button type="button" className="button is-static">EXPERIENCE</button>
                </p>
              </div>
            </div>
          ) : null }
        </section>
        <footer className="modal-card-foot is-justify-content-flex-end">
          <button
            onClick={ handleWithdraw }
            disabled={ pendingTx || !poolData.canWithdraw }
            className={ `button is-success ${pendingTx ? 'is-loading' : ''}` }
          >
            Confirm
          </button>
          <button className="button" onClick={ onModalClose }>Cancel</button>
        </footer>
      </div>
    </div>
  );
}

export default PoolCardWithdrawModal;
