import { ethers } from 'ethers';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { providers } from "ethers";

import { getAddress } from '../utils/commons';

export const getContract = (token, abi) => new ethers.Contract(getAddress(token), abi, getDefaultProvider());

export const getSignedContract = async (token, abi) => {
  const userConnectionStatus = getUserConnectionStatus();
  const userProvider = await getUserProvider();

  if(isUserConnected() === true) {
    if(userConnectionStatus.walletProvider === 'METAMASK') {
      await changeNetwork();
    }

    const userSigner = userProvider.getSigner();
    return new ethers.Contract(getAddress(token), abi, userSigner);
  } else {
    return null;
  }
}

export const getNodeUrl = () => {
  const nodes = Object.entries(process.env).filter(([key, ]) => key.startsWith('REACT_APP_NODE_'));

  const randomInt = (a = 1, b = 0) => {
    const lower = Math.ceil(Math.min(a, b));
    const upper = Math.floor(Math.max(a, b));
    return Math.floor(lower + Math.random() * (upper - lower + 1))
  };

  const randomIndex = randomInt(0, nodes.length - 1)
  // console.log('node', nodes[randomIndex][1]);
  return nodes[randomIndex][1];
}

export const getDefaultProvider = () => new ethers.providers.JsonRpcProvider(getNodeUrl());

export const getUserProvider = async () => {
  const userConnectionStatus = getUserConnectionStatus();
  let userProvider = null;
  // console.log('[getUserProvider] prov',userConnectionStatus.walletProvider);
  switch (userConnectionStatus.walletProvider) {
    case "METAMASK":
      // console.log('Provider ==> Metamask');
      if(typeof window.ethereum !== 'undefined'){
        await window.ethereum.enable();
        const metamaskProvider = new ethers.providers.Web3Provider(window.ethereum);
        userProvider = metamaskProvider;
      } 
      break;

    case "WALLET_CONNECT":
      // console.log('Provider ==> WalletConnect');
      const hostx = getNodeUrl();
      const walletConnectProvider = new WalletConnectProvider({
        chainId:Number(process.env.REACT_APP_NETWORK_ID),
        network:'Avalanche Network',
        rpc: { 43114: hostx }
      });
      await walletConnectProvider.enable();
      const walletConnectEthersProvider = new providers.Web3Provider(walletConnectProvider);
      userProvider = walletConnectEthersProvider; 
      break;
    
    default:
      break;
  }
  return userProvider;
}

export const checkUserNetwork = async () => {
  const userProvider = await getUserProvider();
  if(userProvider !== null){
    const network = await userProvider.getNetwork();
    return network.chainId === Number(process.env.REACT_APP_NETWORK_ID);
  }
} 

export const getWalletAddress = async () => {
  const userProvider = await getUserProvider();
  if(isUserConnected() === true){
    const userSigner = await userProvider.getSigner();
    const address = await userSigner.getAddress();
    return address;
  } else {
    return null;
  }
}

export const attachWalletListeners = async (updateWalletCallback) => {
  if(typeof window.ethereum !== 'undefined') {
    const metamaskProvider = new ethers.providers.Web3Provider(window.ethereum);
    metamaskProvider.on('network', async (newNetwork, oldNetwork) => {
      if (oldNetwork && newNetwork !== oldNetwork) {
        console.warn(`#### NETWORK CHANGED >> From: ${oldNetwork.name} To: ${newNetwork.name} ####`);
        updateWalletCallback(await getWalletAddress());
      }
    });

    window.ethereum.on('accountsChanged', async accounts => {
      console.log('#### Account Changed ####',accounts[0],await getWalletAddress());
      updateWalletCallback(await getWalletAddress());
    });

    window.ethereum.on('connect', async chainId => {
      console.log('#### connect ####',chainId);
      updateWalletCallback(await getWalletAddress());
    });

    window.ethereum.on('disconnect', async (code, reason) => {
      console.log('#### disconnected ####');
      updateWalletCallback(await getWalletAddress());
    });

    window.ethereum.on('message', async message => {
      console.log('#### New Message Arrived #### ',message);
    });

  } 
}

const changeNetwork = async () => {
  if (window.ethereum) {
    try {
      await window.ethereum.enable();
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: process.env.REACT_APP_CHAIN_ID }],
      });
    } catch (switchError) {
      console.error(switchError);
    }
  }
};

export const isUserConnected = () => {
  let uxStorage = getUserConnectionStatus();
  return uxStorage.isInitialized;
}

const saveUserConnectionStatus = (isConnected = false, walletProvider = "METAMASK") => {
  let uxStorage = JSON.parse(localStorage.getItem("ux")) ;
  if(uxStorage === null){
    uxStorage = {};
  } 
  uxStorage.isInitialized = isConnected;
  if(isConnected === true){
    uxStorage.walletProvider = walletProvider;
  }else{
    uxStorage.walletProvider = "NOT_SELECTED";
  }
  localStorage.setItem("ux", JSON.stringify(uxStorage));
}

const getUserConnectionStatus = () => {
  let uxStorage = JSON.parse(localStorage.getItem("ux")) ;
  if(uxStorage === null){
    uxStorage = {};
    uxStorage.isInitialized = false;
    uxStorage.walletProvider = "NOT_SELECTED";
    localStorage.setItem("ux", JSON.stringify(uxStorage));
  }
  return uxStorage;
}

export const connect = async (provider = "METAMASK") => {
  let walletAddress = null;
  switch (provider) {
    case "METAMASK":
      if(typeof window.ethereum !== 'undefined'){
        await window.ethereum.enable();
        const metamaskProvider = new ethers.providers.Web3Provider(window.ethereum);
        let network = await metamaskProvider.getNetwork();
        if (network.chainId !== Number(process.env.REACT_APP_NETWORK_ID)){
          await changeNetwork();
        }
        network = await metamaskProvider.getNetwork();
        if (network.chainId === Number(process.env.REACT_APP_NETWORK_ID)){
          const signer = metamaskProvider.getSigner();
          const address = await signer.getAddress();
          saveUserConnectionStatus(true, provider);
          walletAddress = address;
        }
      }
      break;

    case "WALLET_CONNECT":
      const hostx = getNodeUrl();
      const walletConnectProvider = new WalletConnectProvider({
        chainId: Number(process.env.REACT_APP_NETWORK_ID),
        network:'Avalanche Network',
        rpc: { 43114: hostx }
      });
      await walletConnectProvider.enable();
      const walletConnectEthersProvider = new providers.Web3Provider(walletConnectProvider);
      const network = await walletConnectEthersProvider.getNetwork();
      // if(network.name !== Number(process.env.REACT_APP_NETWORK_ID)){ TODO CHANGE NETWORK }
      console.log('Logued in ChainID --> ',network.chainId);
      // if(network.name === Number(process.env.REACT_APP_NETWORK_ID)){
        const signer = walletConnectEthersProvider.getSigner();
        const address = await signer.getAddress();
        saveUserConnectionStatus(true,provider);
        walletAddress = address;
      // }
      break;
    default:
      break;
  }
  return walletAddress;
}

export const disconnect = async () => {
  saveUserConnectionStatus(false);
}

export const addToMetamask = (address, symbol, decimals, image) => {
  const { ethereum } = window;

  if (typeof ethereum !== undefined) {
    ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address,
          symbol,
          decimals,
          image,
        },
      },
    })
  }
}

export const addNetwork = async () => {
  const { ethereum } = window;
  if ( ! ethereum) {
    alert('Please install metamask plugin in your browser');
    return;
  }

  const params = [
    {
      chainId: process.env.REACT_APP_CHAIN_ID,
      chainName: process.env.REACT_APP_CHAIN_NAME,
      nativeCurrency: {
        name: process.env.REACT_APP_NETWORK_TOKEN.toUpperCase(),
        symbol: process.env.REACT_APP_NETWORK_TOKEN.toUpperCase(),
        decimals: 18,
      },
      rpcUrls: [process.env.REACT_APP_NODE_1],
      blockExplorerUrls: [process.env.REACT_APP_EXPLORER],
    }
  ];

  const metamaskProvider = new ethers.providers.Web3Provider(ethereum);
  const network = await metamaskProvider.getNetwork();

  if (network.chainId === Number(process.env.REACT_APP_NETWORK_ID)) {
    alert(`${process.env.REACT_APP_CHAIN_NAME} Network has already been added to Metamask.`);
    return;
  }

  ethereum.request({
    method: 'wallet_addEthereumChain',
    params
  })
  .then(() => console.log('Success'))
  .catch((error) => console.error('Error', error.message));
}
