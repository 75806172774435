const vaultInitialState = {
  loading: false,
  firstLoad: true,
  tvl: 0,
  maxFarmApy: 0,
  totalBuyBackAmount: 0,
  vaults: [
    {
      pid: 1,
      farmPid: 1,
      token: 'banksy',
      quoteToken: 'ftm',
      isTokenOnly: false,
      name: 'BANKSY-WFTM LP',
      swap: 'SpookySwap',
      from: 'Banksy',
      strategy: '0x8dCA45D34930123645d393151a8EA27Ef3d66b98',
    },
    {
      pid: 0,
      farmPid: 0,
      token: 'banksy',
      quoteToken: 'usdc',
      isTokenOnly: false,
      name: 'BANKSY-USDC LP',
      swap: 'SpookySwap',
      from: 'Banksy',
      strategy: '0x47DEe7A5594Fe3c80aE1F635DA8094De6eeeaCd1',
    },
    {
      pid: 2,
      farmPid: 2,
      token: 'banksy',
      quoteToken: 'usdc',
      isTokenOnly: true,
      name: 'BANKSY',
      swap: 'SpookySwap',
      from: 'Banksy',
      strategy: '0xC7ecf517AC804FF49854e5B0e499635A10CA3BDc',
    },
  ],
}

export default vaultInitialState;
