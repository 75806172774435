import React, { useState, useContext } from 'react';
import { faGithub, faMediumM, faTwitter, faTelegram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faBook, faPalette } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NativeTokenPriceContext } from '../../../context/nativeTokenPrice';
// import TransactionsInfo  from './components/TransactionsInfo';

import { addNetwork, addToMetamask } from '../../../blockchain/commons';
import { getAddress, getLpAddress, nativeTokenName, networkTokenName, stableTokenName } from '../../../utils/commons';

import './Footer.css';

const Footer = () => {
  const { nativeTokenPrice } = useContext(NativeTokenPriceContext);

  const [isActive, setIsActive] = useState(false);

  const nativeTokenAddress = getAddress(process.env.REACT_APP_NATIVE_TOKEN);

  const toggleMenu = (evt) => {
    evt.preventDefault();
    setIsActive(!isActive);
  }

  const blurOnClick = (evt) => {
    evt.target.blur();
    if (isActive) {
      setIsActive(prevState => !prevState);
    }
  }

  const handleAddTokenToMetamask = (evt) => {
    evt.preventDefault();

    const address = getAddress(process.env.REACT_APP_NATIVE_TOKEN);
    const symbol = nativeTokenName;
    const decimals = process.env.REACT_APP_DECIMALS;
    const image = `https://ftm.banksy.farm/images/${process.env.REACT_APP_NATIVE_TOKEN}.png`;

    addToMetamask(address, symbol, decimals, image);
    blurOnClick(evt);
  }

  const handleAddLpNetworkToMetamask = (evt) => {
    evt.preventDefault();

    const address = getLpAddress(process.env.REACT_APP_NATIVE_TOKEN, process.env.REACT_APP_NETWORK_TOKEN);
    const symbol = `${process.env.REACT_APP_NATIVE_TOKEN.slice(0, 4)}-${process.env.REACT_APP_NETWORK_TOKEN}`.toUpperCase();
    const decimals = process.env.REACT_APP_DECIMALS;
    const image = `https://ftm.banksy.farm/images/${process.env.REACT_APP_NATIVE_TOKEN}${process.env.REACT_APP_NETWORK_TOKEN}.png`;

    addToMetamask(address, symbol, decimals, image);
    blurOnClick(evt);
  }

  const handleAddLpStableToMetamask = (evt) => {
    evt.preventDefault();

    const address = getLpAddress(process.env.REACT_APP_NATIVE_TOKEN, process.env.REACT_APP_STABLE_TOKEN);
    const symbol = `${process.env.REACT_APP_NATIVE_TOKEN.slice(0, 4)}-${process.env.REACT_APP_STABLE_TOKEN}`.toUpperCase();
    const decimals = process.env.REACT_APP_DECIMALS;
    const image = `https://ftm.banksy.farm/images/${process.env.REACT_APP_NATIVE_TOKEN}${process.env.REACT_APP_STABLE_TOKEN}.png`;

    addToMetamask(address, symbol, decimals, image);
    blurOnClick(evt);
  }

  const handleAddNetwork = (evt) => {
    evt.preventDefault();
    addNetwork();
  }

  return (
    <nav className="navbar is-fixed-bottom has-shadow" role="navigation" aria-label="main navigation">
      <div className="container is-fluid">
        <div className="navbar-brand">
          <a href={ (process.env.REACT_APP_NATIVE_PRICE_LINK || '#').replace(':token', nativeTokenAddress) } className="navbar-item brand-title is-hidden-desktop" target="_blank" rel="noreferrer">
            <figure className="image is-24x24">
              <img src={ `/images/farms/${process.env.REACT_APP_NATIVE_TOKEN}.png` } alt={ nativeTokenName } />
            </figure>
            <span className="ml-1">${ nativeTokenPrice.amount.toFixed(3) }</span>

            <span className="tag is-primary is-small ml-5">
                <span className="icon-text">
                  <span className="icon">
                    <FontAwesomeIcon icon={ faPalette } />
                  </span>
                  Original ArtWorks
                </span>
              </span>

          </a>

          <button type="button" className={`navbar-burger ${isActive ? 'is-active' : null}`} aria-label="menu" aria-expanded="false" data-target="footerNavbar" onClick={ toggleMenu }>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </button>
        </div>

        <div id="footerNavbar" className={ `navbar-menu ${isActive ? 'is-active' : null}` }>
          <div className="navbar-start">
            <a href="https://github.com/BanksyFarm" target="_blank" rel="noreferrer" className="navbar-item">
              <span className="icon-text">
                <span className="icon">
                  <FontAwesomeIcon icon={ faGithub } size="lg" />
                </span>
                <em className="is-hidden-desktop">
                  GitHub
                </em>
              </span>
            </a>
            <a href="https://docs.banksy.farm/" target="_blank" rel="noreferrer" className="navbar-item">
              <span className="icon-text">
                <span className="icon">
                  <FontAwesomeIcon icon={ faBook } size="lg" />
                </span>
                <em className="is-hidden-desktop">
                  Docs
                </em>
              </span>
            </a>
            <a href="https://banksyfarm.medium.com/" target="_blank" rel="noreferrer" className="navbar-item">
              <span className="icon-text">
                <span className="icon">
                  <FontAwesomeIcon icon={ faMediumM } size="lg" />
                </span>
                <em className="is-hidden-desktop">
                  Medium
                </em>
              </span>
            </a>
            <a href="https://www.youtube.com/channel/UChfWl-DNpRhLs_2NszfEpoA" target="_blank" rel="noreferrer" className="navbar-item">
              <span className="icon-text">
                <span className="icon">
                  <FontAwesomeIcon icon={ faYoutube } size="lg" />
                </span>
                <em className="is-hidden-desktop">
                  Videos
                </em>
              </span>
            </a>
            <a href="https://twitter.com/banksy_dao" target="_blank" rel="noreferrer" className="navbar-item">
              <span className="icon-text">
                <span className="icon">
                  <FontAwesomeIcon icon={ faTwitter } size="lg" />
                </span>
                <em className="is-hidden-desktop">
                  Twitter
                </em>
              </span>
            </a>
            <div className="navbar-item has-dropdown is-hoverable has-dropdown-up">
              <div className="navbar-link is-arrowless">
                <span className="icon-text">
                  <span className="icon">
                    <FontAwesomeIcon icon={ faTelegram } size="lg" />
                  </span>
                  <em className="is-hidden-desktop">
                    Telegram
                  </em>
                </span>
              </div>

              <div className="navbar-dropdown">
                <a href="https://t.me/BanksyFarm" target="_blank" rel="noreferrer" className="navbar-item">
                  Official
                </a>
                <a href="https://t.me/BanksyFarm" target="_blank" rel="noreferrer" className="navbar-item">
                  Announcements
                </a>
              </div>
            </div>

            <div className="navbar-item has-dropdown is-hoverable has-dropdown-up">
              <div className="navbar-link is-arrowless">
                <figure className="image is-24x24">
                  <img src="/images/metamask.svg" alt="Metamask" />
                </figure>
                <em className="is-hidden-desktop">
                  Metamask
                </em>
              </div>

              <div className="navbar-dropdown">
                <a href="/" className="navbar-item" onClick={ handleAddTokenToMetamask }>
                  Add { nativeTokenName }
                </a>
                <a href="/" className="navbar-item" onClick={ handleAddLpNetworkToMetamask }>
                  Add { nativeTokenName }-{ networkTokenName }
                </a>
                <a href="/" className="navbar-item" onClick={ handleAddLpStableToMetamask }>
                  Add { nativeTokenName }-{ stableTokenName }
                </a>
                <a href="/" className="navbar-item" onClick={ handleAddNetwork }>
                  Add { process.env.REACT_APP_CHAIN_NAME } Network
                </a>
              </div>
            </div>
            {/* <TransactionsInfo/> */}
          </div>
          <div className="navbar-end">
            <div className="navbar-item">
              <span className="tag is-primary is-hidden-mobile">
                <span className="icon-text">
                  <span className="icon">
                    <FontAwesomeIcon icon={ faPalette } />
                  </span>
                  Original ArtWorks
                </span>
              </span>
            </div>
            <a href="https://rugdoc.io/project/banksy-farm-fantom/" target="_blank" rel="noreferrer" className="navbar-item" onClick={ blurOnClick }>
              <em className="has-text-weight-bold">RugDoc</em> <span className="tag is-small is-success is-rounded ml-1">KYC/LOW RISK</span>
            </a>
            <a href="https://paladinsec.co/projects/banksy-finance-ftm/" target="_blank" rel="noreferrer" className="navbar-item" onClick={ blurOnClick }>
              <em className="has-text-weight-bold">Paladin</em> <span className="tag is-small is-info is-rounded ml-1">ONBOARDED</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Footer;
