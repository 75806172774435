import { useState, useContext } from 'react';
import BigNumber from 'bignumber.js';
import { depositPoolV1 } from '../../../../../blockchain/pools';
import { trunc } from '../../../../../utils/commons';

import { ToastContext } from '../../../../../context/toast';

import PoolCardNftSelector from './PoolCardNftSelector';

const PoolCardDepositModal = ({ poolData, isModalActive, onModalClose }) => {
  const { addToast } = useContext(ToastContext);

  const [pendingTx, setPendingTx] = useState(false);
  const [val, setVal] = useState('');
  const [nftID, setNftID] = useState(poolData.userHasNFT ? poolData.userNftID : 999999999);

  const userBalance = (mul = 1) => {
    const decimals = poolData.stakedTokenDecimals;

    return trunc(poolData.userBalance, mul, decimals);
  }

  const onValChange = (evt) => {
    const { target } = evt;
    setVal(target.value);
  }

  const canDeposit = () => {
    const value = new BigNumber(val);
    return !value.isNaN() && value.gt(0) && value.lte(userBalance());
  }

  const handleDeposit = async () => {
    let tx;
    try {
      setPendingTx(true);
      const decimals = poolData.stakedTokenDecimals;
      const amount = new BigNumber(val).times(new BigNumber(10).pow(decimals));

      tx = await depositPoolV1(poolData.address, amount.toFixed(), nftID);
      await tx.wait();
      addToast('Wall Token Stake succeeded', 'is-success');
      setVal('0');
      setPendingTx(false);
      onModalClose();
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }
    if (tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('Wall Token Stake failed', 'is-danger');
      setPendingTx(false);
    }
  }

  return (
    <div className={`modal ${isModalActive ? 'is-active' : null}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Deposit { poolData.stakedToken?.toUpperCase() }</p>
          <button className="delete" aria-label="close" onClick={ onModalClose }></button>
        </header>
        <section className="modal-card-body">
          <div className="buttons is-right mb-0">
            <button className="button is-success is-small is-outlined" onClick={ () => setVal(userBalance(0.25).toFixed()) }>
              25%
            </button>
            <button className="button is-success is-small is-outlined" onClick={ () => setVal(userBalance(0.5).toFixed()) }>
              50%
            </button>
            <button className="button is-success is-small is-outlined" onClick={ () => setVal(userBalance(0.75).toFixed()) }>
              75%
            </button>
            <button className="button is-success is-small is-outlined" onClick={ () => setVal(userBalance().toFixed()) }>
              100%
            </button>
            <button className="button is-text" onClick={ () => setVal(userBalance().toFixed()) }>
              { userBalance().toFormat(6) } max
            </button>
          </div>
          <div className="field has-addons">
            <div className="control is-expanded">
              <input className="input has-text-right" type="text" placeholder="0" value={ val } onChange={ onValChange } />
            </div>
            <div className="control">
              <button type="button" className="button is-static">
                { poolData.stakedToken.toUpperCase() }
              </button>
            </div>
          </div>
          <PoolCardNftSelector poolData={ poolData } setNftID={ setNftID } nftID={ nftID } />
        </section>
        <footer className={ `modal-card-foot is-justify-content-flex-end ${pendingTx ? 'is-loading' : ''}` }>
          <button
            onClick={ handleDeposit }
            disabled={ pendingTx || !canDeposit() }
            className={ `button is-success ${pendingTx ? 'is-loading' : ''}` }
          >
            Confirm
          </button>
          <button className="button" onClick={ onModalClose }>Cancel</button>
        </footer>
      </div>
    </div>
  );
}

export default PoolCardDepositModal;
