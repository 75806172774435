import React, { useState, useContext, useMemo } from 'react';
import BigNumber from 'bignumber.js';
import  { harvestFarm, depositFarm } from '../../../blockchain/farms';

import { ToastContext } from '../../../context/toast';

const FarmHarvestSection = ({ farmData }) => {
  const { addToast } = useContext(ToastContext);

  const [pendingHarvestTx, setPendingHarvestTx] = useState(false);

  const earned = () => {
    const earnedValue = new BigNumber(farmData.pendingToken)
      .div(new BigNumber(10).pow(process.env.REACT_APP_DECIMALS));

    return earnedValue.toFormat(earnedValue.eq(0) || earnedValue.gt(1) ? 2 : 6);
  }

  const canHarvest = useMemo(() => {
    return new BigNumber(farmData.pendingToken).gt(0);
  }, [farmData.pendingToken]);

  const handleHarvest = async () => {
    let tx;
    try {
      setPendingHarvestTx(true);
      tx = await harvestFarm(farmData.pid);
      await tx.wait();
      addToast('Harvest succeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }
    if (tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('Harvest failed', 'is-danger');
    }

    setPendingHarvestTx(false);
  }

  const handleCompound = async () => {
    setPendingHarvestTx(true);
    let tx;
    try {
      tx = await depositFarm(farmData.pid, new BigNumber(farmData.pendingToken).toFixed());
      await tx.wait();
      addToast('Successfull Compound!', 'is-success');
    } catch(error) {
      tx = { error: error.data?.message || error.message };
    }
    if(tx.error !== undefined) {
      addToast(tx.error, 'is-danger');
    }

    setPendingHarvestTx(false);
  }

  return (
    <>
      <small>EARNED</small>
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <h5 className="is-size-5 has-text-weight-semibold">
              { earned() }
            </h5>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <div className="is-flex is-flex-direction-column">
              {farmData.name.toLowerCase() === process.env.REACT_APP_NATIVE_TOKEN ? (
                <button
                  disabled={ pendingHarvestTx || !canHarvest }
                  className={ `button is-primary is-outlined mb-1 ${pendingHarvestTx ? 'is-loading' : ''}` }
                  onClick={ handleCompound }
                >
                  COMPOUND
                </button>
              ) : null}
              <button
                disabled={ pendingHarvestTx || !canHarvest }
                className={ `button is-primary ${pendingHarvestTx ? 'is-loading' : ''}` }
                onClick={ handleHarvest }
              >
                HARVEST
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FarmHarvestSection;
