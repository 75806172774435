import React, { useEffect, useState, useContext } from 'react';
import BigNumber from 'bignumber.js';
import  { approveNft } from '../../../blockchain/nft';

import { ToastContext } from '../../../context/toast';
import { NftContext } from '../../../context/nft';
import { fetchMyNfts } from '../../../blockchain/nft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { depositPoolNft } from '../../../blockchain/pools';
import { Link } from 'react-router-dom';

const PoolDepositNftModal = ({ poolData, isModalActive, onModalClose }) => {
  const { nftState, setNftState } = useContext(NftContext);
  const { addToast } = useContext(ToastContext);

  useEffect(() => {
    const syncMyNfts = async () => {
      setNftState(prevState => ({ ...prevState, loading: true }));
      const nftResult = await fetchMyNfts();
      setNftState(prevState => ({ ...prevState, ...nftResult, loading: false }));
    }

    const intervalId = setInterval(syncMyNfts, 10000);

    syncMyNfts();

    return () => clearInterval(intervalId);
  }, [setNftState]);

  const [pendingTx, setPendingTx] = useState(false);
  const [index, setIndex] = useState(0);

  const handleApprove = async () => {
    let tx;
    try {
      setPendingTx(true);
      tx = await approveNft(poolData.address, nftState.myNfts[index].pid);
      await tx.wait();
      addToast('NFT ArtWork approved', 'is-success');
      setPendingTx(false);
    } catch (error) {
      tx = { error: error.data ? error.data.message : error.message };
    }

    if (tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('NFT ArtWork approve failed', 'is-danger');
      setPendingTx(false);
    }
  }

  const handleStakeNft = async () => {
    let tx;
    try {
      setPendingTx(true);
      tx = await depositPoolNft(poolData.address, nftState.myNfts[index].pid);
      await tx.wait();
      addToast('NFT ArtWork Added', 'is-success');
      setPendingTx(false);
      onModalClose();
    } catch (error) {
      tx = { error: error.data ? error.data.message : error.message };
    }

    if (tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('Token Stake failed', 'is-danger');
      setPendingTx(false);
    }
  }

  const isLoading = () => nftState.firstLoad && nftState.loading;

  const renderResults = () => {
    if (isLoading()) {
      return (
        <span className="icon-text is-align-items-center">
          <span className="icon is-large">
            <FontAwesomeIcon icon={ faCog } spin size="2x" />
          </span>
          <span>Loading...</span>
        </span>
      );
    }

    if (nftState.myNfts.length > 0) {
      return nftState.myNfts.map((nft, i) => (
        <div key={ `nft-card-${nft.pid}` } className={ `${i === index ? '' : 'is-hidden'}` }>
          <figure className="image is-16by9 mb-3">
            <img src={ `/images/nfts/generation-${nft.generation}.png` } alt={ nft.name } />
          </figure>
          <p className="title mb-1">{ nft.name }</p>
          <div className="field is-grouped is-grouped-multiline">
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Generation</span>
                <span className="tag mb-0 is-info">{ nft.generation }</span>
              </div>
            </div>
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Experience</span>
                <span className="tag mb-0 is-success">{ new BigNumber(nft.experience).toFormat(0) }</span>
              </div>
            </div>
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Power</span>
                <span className="tag mb-0 is-primary">{ nft.power }</span>
              </div>
            </div>
            <div className="control">
              <div className="tags has-addons">
                <span className="tag mb-0 is-dark">Boost Stake</span>
                <span className="tag mb-0 is-danger">{ new BigNumber(nft.boostStake).toFormat(2) }%</span>
              </div>
            </div>
          </div>
        </div>
      ));
    }

    return (
      <div>
        No NFT cards available. <Link to="/nft-sale">Go to buy</Link>
      </div>
    );
  }

  const renderApproveOrStakeButton = () => {
    if (isLoading() || nftState.myNfts.length === 0) {
      return null;
    }

    if (nftState.myNfts[index].allowance === poolData.address) {
      return (
        <button
          type="button"
          onClick={ handleStakeNft }
          disabled={ pendingTx }
          className={ `button is-success ${pendingTx ? 'is-loading' : ''}` }
        >
          Confirm
        </button>
      );
    }

    return (
      <button
        type="button"
        onClick={ handleApprove }
        disabled={ pendingTx }
        className={ `button is-warning ${pendingTx ? 'is-loading' : ''}` }
      >
        Approve
      </button>
    );
  }

  return (
    <div className={`modal ${isModalActive ? 'is-active' : null}`}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Select NFT ArtWork</p>
          <button className="delete" aria-label="close" onClick={ onModalClose }></button>
        </header>
        <section className="modal-card-body">
          { renderResults() }
        </section>
        <footer className="modal-card-foot is-justify-content-space-between">
          <div className="buttons mb-0">
            <button
              type="button"
              className="button"
              disabled={ pendingTx || index === 0 || isLoading() }
              onClick={() => setIndex(prevState => prevState - 1)}
            >
              prev
            </button>
            <button
              type="button"
              className="button"
              disabled={ pendingTx || index >= (nftState.myNfts.length - 1) || isLoading() }
              onClick={() => setIndex(prevState => prevState + 1)}
            >
              next
            </button>
          </div>
          <div className="buttons">
            { renderApproveOrStakeButton() }
            <button
              type="button"
              disabled={ pendingTx }
              className="button"
              onClick={ onModalClose }
            >
              Cancel
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default PoolDepositNftModal;
