import React, { useState, useContext } from 'react';
import BigNumber from 'bignumber.js';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { WalletContext } from '../../../../../context/wallet';
import { ToastContext } from '../../../../../context/toast';

import { approvePool } from '../../../../../blockchain/pools';

import PoolCardWithdrawModal from './PoolCardWithdrawModal';
import PoolCardDepositModal from './PoolCardDepositModal';

const PoolCardStakeSection = ({ poolData }) => {
  const { walletAddress, handleConnectClick } = useContext(WalletContext);
  const { addToast } = useContext(ToastContext);

  const [pendingTx, setPendingTx] = useState(false);
  const [isWithdrawModalActive, setIsWithdrawModalActive] = useState(false);
  const [isDepositModalActive, setIsDepositModalActive] = useState(false);

  const handleWithdrawModalClose = () => setIsWithdrawModalActive(false);
  const handleWithdrawModalOpen = () => setIsWithdrawModalActive(true);

  const handleDepositModalClose = () => setIsDepositModalActive(false);
  const handleDepositModalOpen = () => setIsDepositModalActive(true);

  const poolStakedBalance = () => {
    return new BigNumber(poolData.userAmount)
      .div(new BigNumber(10).pow(poolData.stakedTokenDecimals));
  }

  const userBalance = () => {
    return new BigNumber(poolData.userBalance)
      .div(new BigNumber(10).pow(poolData.stakedTokenDecimals));
  }

  const hasStakedBalance = () => new BigNumber(poolData.userAmount).gt(0);

  const handleApprove = async () => {
    setPendingTx(true);
    let tx;
    try {
      tx = await approvePool(poolData.address, poolData.stakedToken);
      await tx.wait();
      addToast('Wall Staked Token Approve succeeded', 'is-success');
    } catch (error) {
      tx = { error: error.data?.message || error.message };
    }

    if (tx.error !== undefined) {
      console.log('error', tx.error);
      addToast('Wall Staked Token Approve failed', 'is-danger');
    }
    setPendingTx(false);
  }

  const renderUnlockApproveOrStake = () => {
    if (walletAddress === null) {
      return (
        <div className="level-item">
          <button className="button is-primary is-fullwidth" onClick={ handleConnectClick }>
            Unlock
          </button>
        </div>
      );
    }

    if (new BigNumber(poolData.userAllowance).eq(0)) {
      return (
        <div className="level-item">
          <button
            disabled={ pendingTx || poolData.canWithdraw }
            className={ `button is-primary ${pendingTx ? 'is-loading' : ''}` }
            onClick={ handleApprove }
          >
            APPROVE
          </button>
        </div>
      );
    }

    if (hasStakedBalance()) {
      return (
        <>
          <div className="level-item">
            <button disabled={ !poolData.canWithdraw } className="button is-primary is-outlined" onClick={ handleWithdrawModalOpen }>
              <span className="icon">
                <FontAwesomeIcon icon={ faMinus } />
              </span>
            </button>
          </div>
          <div className="level-item">
            <button disabled={ poolData.canWithdraw } className="button is-primary is-outlined" onClick={ handleDepositModalOpen }>
              <span className="icon">
                <FontAwesomeIcon icon={ faPlus } />
              </span>
            </button>
          </div>
        </>
      );
    }

    return (
      <div className="level-item">
        <button disabled={ poolData.canWithdraw } className="button is-primary" onClick={ handleDepositModalOpen }>
          STAKE
        </button>
      </div>
    );
  }

  return (
    <>
      <small>STAKED <span className="has-text-primary">{ poolData.stakedToken.toUpperCase() }</span></small>
      <div className="level is-mobile">
        <div className="level-left">
          <div className="level-item">
            <h5 className="is-size-5 has-text-weight-semibold">
              { poolStakedBalance().toFormat(6) }
            </h5>
          </div>
        </div>
        <div className="level-right">
          { renderUnlockApproveOrStake() }
        </div>
      </div>

      <PoolCardWithdrawModal
        poolData={ poolData }
        isModalActive={ isWithdrawModalActive }
        onModalClose={ handleWithdrawModalClose }
        stakedBalance={ poolStakedBalance() }
      />

      <PoolCardDepositModal
        poolData={ poolData }
        isModalActive={ isDepositModalActive }
        onModalClose={ handleDepositModalClose }
        userBalance={ userBalance() }
      />
    </>
  );
}

export default PoolCardStakeSection;
