import React, { useContext } from 'react';
import BigNumber from 'bignumber.js';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NftContext } from '../../../../../context/nft';

const PoolCardNftSelector = ({ poolData, nftID, setNftID }) => {
  const { nftState } = useContext(NftContext);

  const nftData = nftState.myNfts.find(data => data.pid === nftID);

  if (nftState.myNfts.length > 0 && nftID === 999999999) {
    setNftID(nftState.myNfts[0].pid);
  }

  const prev = () => {
    const index = nftState.myNfts.findIndex(data => data.pid === nftID);
    setNftID(nftState.myNfts[index - 1].pid);
  }

  const next = () => {
    const index = nftState.myNfts.findIndex(data => data.pid === nftID);
    setNftID(nftState.myNfts[index + 1].pid);
  }

  const disablePrevButton = () => {
    if (nftID && !poolData.userHasNFT) {
      const index = nftState.myNfts.findIndex(data => data.pid === nftID);
      return index < 1;
    }

    return true;
  }

  const disableNextButton = () => {
    if (nftID && !poolData.userHasNFT) {
      const index = nftState.myNfts.findIndex(data => data.pid === nftID);
      return index + 1 >= nftState.myNfts.length;
    }

    return true;
  }

  const nftName = (nftData) => {
    return `Morons #${nftData?.pid}`;
  }

  return (
    <>
      {nftState.myNfts.length > 0 ? (
        <div className="columns is-mobile">
          <div className="column is-two-third">
            <figure className="image is-16by9">
              <img src={ `/images/nfts/generation-${nftData?.generation}.png` } alt={ nftName(nftData) } />
            </figure>
          </div>
          <div className="column is-one-third">
            <p className="title is-5">{ nftName(nftData) }</p>
            <p>Experience: { nftData ? new BigNumber(nftData.experience).toFormat(0) : 0 }</p>
            <p>Power: { nftData?.power }</p>
            <p className="mb-3">Boost Stake: { nftData ? new BigNumber(nftData.boostStake).toFormat(2) : 0 }%</p>
            <div className="buttons">
              <button disabled={ disablePrevButton() } type="button" className="button is-small" onClick={prev}>
                <span className="icon">
                  <FontAwesomeIcon icon={ faArrowLeft } />
                </span>
                <span>Prev</span>
              </button>
              <button disabled={ disableNextButton() } type="button" className="button is-small" onClick={next}>
                <span>Next</span>
                <span className="icon">
                  <FontAwesomeIcon icon={ faArrowRight } />
                </span>
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default PoolCardNftSelector;
